@import url('https://marketing-assets.vercel.app/Brice.css');
@import url('https://marketing-assets.vercel.app/Beausite.css');




html,
body {
  font-family: 'Beausite', sans-serif !important;
  font-weight: 400;
  overflow: hidden;
}

* {
  font-family: 'Beausite', sans-serif !important;
}

.pin-validation {
  font-family: 'Brice', sans-serif !important;
  font-weight: 900;
  color: #1C1C1C;
  font-size: 1.5rem;
  font-style: normal;
  line-height: 1.5rem
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: inherit !important;
  box-shadow: none !important;
  border-color: #E0E0E0 !important;
}

input:-webkit-autofill {
  color: inherit !important;
  border-color: #E0E0E0 !important;
}

input:autofill,
input:-webkit-autofill-strong-password,
input:-webkit-autofill-strong-password-viewable,
input:-webkit-autofill-and-obscured {
  color: inherit !important;
  border-color: #E0E0E0 !important;
}

#just-form-layout {
  --transition-speed: 0.4s;
  --effect: ease-in-out;
  position: fixed;
  transition: transform var(--transition-speed) var(--effect);
}


/* smartphones, iPhone, portrait 480x320 phones */
@media screen and (min-width: 320px) {
  #just-form-layout {
    width: 100%;
    height: 100%;
    z-index: 2147483645;
  }
}

/* smartphones, iPhone, portrait 480x320 phones */
@media screen and (max-width: 481px) {
  #just-form-layout {
    width: 100%;
    height: 100%;
    z-index: 2147483645;
    transform: translateY(100%);
  }

  .slideIn {
    transform: translateY(0%) !important;
  }
}

/* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
@media screen and (min-width: 481px) {
  #just-form-layout {
    width: 100%;
    height: 100%;
    z-index: 2147483645;
  }
}

#__next {
  display: flex;
  justify-content: center;
  position: relative;
}

/* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
@media screen and (min-width: 790px) {
  #just-form-layout {
    width: 50%;
    height: 75%;
  }
}

/* tablet, landscape iPad, lo-res laptops ands desktops */
@media screen and (min-width: 961px) {
  #just-form-layout {
    width: 45%;
    height: 75%;
    box-shadow: 0px 24px 38px rgba(0, 0, 0, 0.04), 0px 9px 46px rgba(0, 0, 0, 0.06), 0px 11px 15px rgba(0, 0, 0, 0.1);
  }
}

/* big landscape tablets, laptops, and desktops */
@media screen and (min-width: 1025px) {
  #just-form-layout {
    width: 40%;
    height: 95%;
  }
}

/* hi-res laptops and desktops */
@media screen and (min-width: 1281px) {
  #just-form-layout {
    width: 35%;
    height: 95%;
  }
}

/* hi-res laptops and desktops */
@media screen and (min-width: 1400px) {
  #just-form-layout {
    width: 28%;
    height: 95%;
  }
}

/* hi-res laptops and desktops */
@media screen and (min-width: 2400px) {
  #just-form-layout {
    width: 28%;
    height: 48%;
  }
}

/* wait for desktop version */
/* @media screen and (max-height: 900px) and (min-width: 1281px) {
  #just-form-layout {
    height: 95%;
  }
} */

.chakra-modal__content-container {
  width: 100% !important;
  position: absolute !important;
  height: unset !important;
}

#chakra-toast-manager-top {
  z-index: 99999999999 !important;
}
svg.spinner {
  display: block;
  margin: auto;
  background-color: rgba(255, 255, 255, 0);
}

/* Overlay styles */
.overlay {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  background-color: rgba(255, 255, 255, 0.75);
  opacity: 0;
  transition: opacity 0.5s;
}
.overlay.show {
  opacity: 1;
  margin: 0px;
}
.overlay .overlay-content {
  width: 100%;
  transform: scale(0.8);
  transition: transform 0.8s;
  font-family: Roboto, sans-serif;
  text-align: center;
}
.overlay.show .overlay-content {
  transform: scale(1);
}

.overlay footer {
  margin-top: 0;
  background-color: transparent;
}

.cc-icon {
  width: 30px;
  height: auto;
  padding: 0 2px;
  margin-right: 4px;
}

/* CSS variables. */
:root {
	--PhoneInput-color--focus: #03b2cb;
	--PhoneInputInternationalIconPhone-opacity: 0.8;
	--PhoneInputInternationalIconGlobe-opacity: 0.65;
	--PhoneInputCountrySelect-marginRight: 0.35em;
	--PhoneInputCountrySelectArrow-width: 0.3em;
	--PhoneInputCountrySelectArrow-marginLeft: var(--PhoneInputCountrySelect-marginRight);
	--PhoneInputCountrySelectArrow-borderWidth: 1px;
	--PhoneInputCountrySelectArrow-opacity: 0.45;
	--PhoneInputCountrySelectArrow-color: currentColor;
	--PhoneInputCountrySelectArrow-color--focus: var(--PhoneInput-color--focus);
	--PhoneInputCountrySelectArrow-transform: rotate(45deg);
	--PhoneInputCountryFlag-aspectRatio: 1.5;
	--PhoneInputCountryFlag-height: 1em;
	--PhoneInputCountryFlag-borderWidth: 1px;
	--PhoneInputCountryFlag-borderColor: rgba(0,0,0,0.5);
	--PhoneInputCountryFlag-borderColor--focus: var(--PhoneInput-color--focus);
	--PhoneInputCountryFlag-backgroundColor--loading: rgba(0,0,0,0.1);
}

.PhoneInput {
	/* This is done to stretch the contents of this component. */
	display: flex;
	align-items: center;
}

.PhoneInputInput {
	/* The phone number input stretches to fill all empty space */
	flex: 1 1;
	/* The phone number input should shrink
	   to make room for the extension input */
	min-width: 0;
}

.PhoneInputCountryIcon {
	width: calc(var(--PhoneInputCountryFlag-height) * var(--PhoneInputCountryFlag-aspectRatio));
	height: var(--PhoneInputCountryFlag-height);
}

.PhoneInputCountryIcon--square {
	width: var(--PhoneInputCountryFlag-height);
}

.PhoneInputCountryIcon--border {
	/* Removed `background-color` because when an `<img/>` was still loading
	   it would show a dark gray rectangle. */
	/* For some reason the `<img/>` is not stretched to 100% width and height
	   and sometime there can be seen white pixels of the background at top and bottom. */
	background-color: var(--PhoneInputCountryFlag-backgroundColor--loading);
	/* Border is added via `box-shadow` because `border` interferes with `width`/`height`. */
	/* For some reason the `<img/>` is not stretched to 100% width and height
	   and sometime there can be seen white pixels of the background at top and bottom,
	   so an additional "inset" border is added. */
	box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor),
		inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor);
}

.PhoneInputCountryIconImg {
	/* Fixes weird vertical space above the flag icon. */
	/* https://gitlab.com/catamphetamine/react-phone-number-input/-/issues/7#note_348586559 */
	display: block;
	/* 3rd party <SVG/> flag icons won't stretch if they have `width` and `height`.
	   Also, if an <SVG/> icon's aspect ratio was different, it wouldn't fit too. */
	width: 100%;
	height: 100%;
}

.PhoneInputInternationalIconPhone {
	opacity: var(--PhoneInputInternationalIconPhone-opacity);
}

.PhoneInputInternationalIconGlobe {
	opacity: var(--PhoneInputInternationalIconGlobe-opacity);
}

/* Styling native country `<select/>`. */

.PhoneInputCountry {
	position: relative;
	align-self: stretch;
	display: flex;
	align-items: center;
	margin-right: var(--PhoneInputCountrySelect-marginRight);
}

.PhoneInputCountrySelect {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 1;
	border: 0;
	opacity: 0;
	cursor: pointer;
}

.PhoneInputCountrySelect[disabled],
.PhoneInputCountrySelect[readonly] {
	cursor: default;
}

.PhoneInputCountrySelectArrow {
	display: block;
	content: '';
	width: var(--PhoneInputCountrySelectArrow-width);
	height: var(--PhoneInputCountrySelectArrow-width);
	margin-left: var(--PhoneInputCountrySelectArrow-marginLeft);
	border-style: solid;
	border-color: var(--PhoneInputCountrySelectArrow-color);
	border-top-width: 0;
	border-bottom-width: var(--PhoneInputCountrySelectArrow-borderWidth);
	border-left-width: 0;
	border-right-width: var(--PhoneInputCountrySelectArrow-borderWidth);
	transform: var(--PhoneInputCountrySelectArrow-transform);
	opacity: var(--PhoneInputCountrySelectArrow-opacity);
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon + .PhoneInputCountrySelectArrow {
	opacity: 1;
	color: var(--PhoneInputCountrySelectArrow-color--focus);
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
	box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor--focus),
		inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor--focus);
}

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon .PhoneInputInternationalIconGlobe {
	opacity: 1;
	color: var(--PhoneInputCountrySelectArrow-color--focus);
}
.superdrawer-container{--transition-speed: .4s;--effect: ease-in-out;overflow:hidden}.superdrawer{background:#fff;width:100%;max-height:100%;overflow:hidden;position:absolute;transition:max-height var(--transition-speed) var(--effect);z-index:1000}.superdrawer.bottom{left:0;bottom:0;max-height:0}.superdrawer-container.in.open .bottom{max-height:100%;overflow-y:hidden}.superdrawer-overlay-wrapper{background-color:#fffc;height:100%;width:100%;bottom:0;z-index:1000;left:0;position:absolute}.justdrawer-container{overflow:hidden;--transition-speed: .3s;--effect: ease }.justdrawer{background:#fff;width:100%;max-height:100%;overflow:hidden;position:absolute;z-index:1000}.justdrawer-container:not(.swiping) .justdrawer-content{transition:height var(--transition-speed) var(--effect),opacity var(--transition-speed) var(--effect)}.justdrawer.bottom{left:0;bottom:0}.justdrawer-container.in.open .bottom{max-height:100%;overflow-y:hidden}.justdrawer-container.in .justdrawer-overlay-wrapper{height:100%;width:100%;bottom:0;z-index:1000;left:0;position:absolute}.justdrawer-container:not(.swiping) .justdrawer-overlay-wrapper{transition:background-color var(--transition-speed) var(--effect)}

